import React from 'react'
import full01 from '../assets/img/gallery/fulls/01.jpg'
import full02 from '../assets/img/gallery/fulls/02.jpg'
import full03 from '../assets/img/gallery/fulls/03.jpg'
import full04 from '../assets/img/gallery/fulls/04.jpg'
import full05 from '../assets/img/gallery/fulls/05.jpg'
import full06 from '../assets/img/gallery/fulls/06.jpg'
import full07 from '../assets/img/gallery/fulls/07.jpg'
import full08 from '../assets/img/gallery/fulls/08.jpg'
import full09 from '../assets/img/gallery/fulls/09.jpg'
import full10 from '../assets/img/gallery/fulls/10.jpg'
import thumb01 from '../assets/img/gallery/thumbs/01.jpg'
import thumb02 from '../assets/img/gallery/thumbs/02.jpg'
import thumb03 from '../assets/img/gallery/thumbs/03.jpg'
import thumb04 from '../assets/img/gallery/thumbs/04.jpg'
import thumb05 from '../assets/img/gallery/thumbs/05.jpg'
import thumb06 from '../assets/img/gallery/thumbs/06.jpg'
import thumb07 from '../assets/img/gallery/thumbs/07.jpg'
import thumb08 from '../assets/img/gallery/thumbs/08.jpg'
import thumb09 from '../assets/img/gallery/thumbs/09.jpg'
import thumb10 from '../assets/img/gallery/thumbs/10.jpg'
import pic01 from '../assets/img/pic01.jpg'
import pic02 from '../assets/img/pic02.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Form from '../components/Form'
import { InlineWidget } from "react-calendly"


const images01 = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
]

const images02 = [
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '7',
    source: full07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
]

const images03 = [
  {
    id: '8',
    source: full08,
    thumbnail: thumb08,
    caption: 'Photo 8',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '9',
    source: full09,
    thumbnail: thumb09,
    caption: 'Photo 9',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '10',
    source: full10,
    thumbnail: thumb10,
    caption: 'Photo 10',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    landscape: true,
  },
]

const yearsOld = Math.floor(((new Date()).getTime()-(new Date("1979-03-13")).getTime()) / 31536000000)

const IndexPage = () => (
  <Layout>
    <section className="intro">
      <header>
        <h1>Joakim Platbarzdis</h1>
        <p>
          Father, product leader, and musician
        </p>
        <ul className="actions">
          <li>
            <a href="#first" className="arrow scrolly">
              <span className="label">Next</span>
            </a>
          </li>
        </ul>
      </header>
      <div className="content">
        <span className="image fill" data-position="center">
          <img src={pic01} alt="" />
        </span>
      </div>
    </section>

    <section id="first">
      <header>
        <h2>Who am I</h2>
      </header>
      <div className="content">
        <p>
          <ul>
            <li>I am a proud father of three boys, living in Älvsjö outside Stockholm with their
          lovely mother</li>
            <li>I have a professional background in {' '}
              <a href="#software-engineering">software engineering</a>,{' '} 
              <a href="#product-management">product management</a>, and{' '}
              <a href="#leadership">leadership</a>/C-level positions in 
              Martech/Retailtech, Fintech/Wealthtech/Regtech, and Foodtech
            </li>
            <li>
              I am a small business owner, musician, composer, sound engineer, and producer of
              music
            </li>
          </ul>
        </p>
      </div>
    </section>

    <section>
      <header>
        <h2>General business</h2>
      </header>
      <div className="content">
        <p>
          <strong>I’ve found that the truth to a successful business is 
          to build a vision in the golden intersection between the company goals 
          and the customer needs, and focus all energy in a unified effort as one 
          company to form and execute strategies that embody that vision.</strong>
        </p>
        <p>
          To reach the highest level of unification, looking after each other must 
          come first - making sure every single member of the organisation is seen, 
          heard, has the right tools, opportunities, and compensation. Coupled with 
          transparency and efficient communication, this will allow vision and 
          mission to saturate the business, and will make sure all decisions made 
          are the right ones, both big and small.
        </p>
        <p>
          On my journey from software engineer, to product manager, to senior manager 
          I’ve been fortunate enough to learn to understand the “full stack”, from 
          company mission, to vision, to strategic initiatives, to tactical epics 
          and stories, I imagine like a software analogy to the Wright brothers’ 
          dream of flight, to tightening the nuts and bolts with a spanner before 
          taking flight for the very first time.
        </p>
      </div>
    </section>

    <section id="leadership">
      <header>
        <h2>Leadership</h2>
        <p>
          More to come, please review <a href="https://www.linkedin.com/in/joakimpl/">my LinkedIn profile</a> for more details. Full CV available upon request.
        </p>
      </header>
      <div className="content">
        <p>
          At Bricknode, I delivered an increase and raised focus quality of the time available for the engineering team by efficiently reconfiguring Jira projects and workflows to a process made concisely scoped and prioritised to company goals, aligning cross-departmentally, and retrospectives.
        </p>
        <p>
          I introduced technical strategy for next gen SaaS value offering in GCP using Cloud Run/GKE, GitOps, CI/CD pipelines, flux, and Atlantis while progressing existing .NET Framework virtual machine based applications.
        </p>
        <p>
          I accomplished a reduction of issues in conjunction with new releases by fusing the QA and dev workflows, and introducing adequate incident management.
        </p>
        <p>
          I designed a company wide release structure with an appointed Release Manager.
        </p>
        <p>
          I spearheaded human resources changes for continuous improvement of individual contributor quality of work environment, enablement, and autonomy.
        </p>
        <p>
            I joined as contributor to the information security management system (ISMS) team for risk analysis and ISO27001 compliance.
        </p>
      </div>
    </section>

    <section id="product-management">
      <header>
        <h2>Product Management</h2>
        <p>
          More to come, please review <a href="https://www.linkedin.com/in/joakimpl/">my LinkedIn profile</a> for more details. Full CV available upon request.
        </p>
      </header>
      <div className="content">
        <p>
          At Bricknode, I re-ignited the product management function to align with the Pragmatic Marketing Framework, focussing on the company goals with measurable initiatives.
        </p>
      </div>
    </section>

    <section id="software-engineering">
      <header>
        <h2>Software engineering</h2>
        <p>
          More to come, please review <a href="https://www.linkedin.com/in/joakimpl/">my LinkedIn profile</a> for more details. Full CV available upon request.
        </p>
      </header>
      <div className="content">
        <p>
          At Bricknode, I directed transition to DevSecOps from a traditional Software Engineering and Site Reliability Engineering structure, with focus on removing bottlenecks and increasing throughput of prioritised functionality.
        </p>
      </div>
    </section>

    <section>
      <header>
        <h2>Book an online meeting</h2>
        <p>
          <strong>This time selector</strong> is powered by calendly.com and will give you
          the best chance of finding an available time slot for an online meeting.
        </p>
        <p> For <em>in-person meetings</em>, please use the form at the bottom of this page or send an e-mail  
          to <a href="mailto:hello@mettle.se">hello@mettle.se</a>.
        </p>
      </header>
      <div className="content">
        <InlineWidget url={process.env.GATSBY_CALENDLY_SCHEDULING_URL} styles={{"minWidth": "320px", "height": "956px"}} />
      </div>
    </section>
    <section>
      <header>
        <h2>Get in touch</h2>
      </header>
      <div className="content">
        <p>
          <strong>I look forward</strong> to hearing from you.
        </p>
        <Form />
      </div>
      <footer>
        <ul className="items">
          <li>
            <h3>Email</h3>
            <a href="mailto:hello@mettle.se">hello@mettle.se</a>
          </li>
          <li>
            <h3>Address</h3>
            <span>Unit 96, 15 Ingestre Place, Soho, London, England, W1F 0DU</span>
          </li>
          <li>
            <h3>Elsewhere</h3>
            <ul className="icons">
              <li>
                <a href="https://twitter.com/kdelacreme" className="icon brands fa-twitter">
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/kdelacreme/" className="icon brands fa-instagram">
                  <span className="label">Instagram</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/joakimpl/" className="icon brands fa-linkedin-in">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/kdelacreme" className="icon brands fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </footer>
    </section>

    <div className="copyright">
      &copy; Mettle AB. All rights reserved.
    </div>
  </Layout>
)

export default IndexPage
