import React from 'react';

export default class Form extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
        name: "",
        email: "",
        message: "",
        sending: false,
        success: false
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ sending: true });

    try {
        const response = await fetch(
            process.env.GATSBY_CREATE_ISSUE_API_URL,
            {
                method: "post",
                body: JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    message: this.state.message
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        if(response.status == 201) {
            const result = await response.json();
            this.setState({ success: true, issue: result.issue});
        }
        this.setState({ sending: false });
    } catch (e) {

    }
  }

  handleChange(event) {
    let update = {};
    update[event.target.name] = event.target.value 
    this.setState(update);
  }

  render() {
    console.log("success is ", this.success);
    if(this.state.success) {
        return (<div>
            <p>Thank you!</p>
            <p>Your message has been <b>successfully transmitted</b> and received as <i>{this.state.issue.key}</i></p>
            <p>You will receive a confirmation via e-mail shortly, and we will get back to you as soon as possible.</p>
        </div>);        
    }
    return  (
      <form onSubmit={this.handleSubmit}>
          <div className="fields">
            <div className="field half">
              <input type="text" name="name" id="name" placeholder="Name" onChange={this.handleChange} />
            </div>
            <div className="field half">
              <input type="email" name="email" id="email" placeholder="Email" onChange={this.handleChange} />
            </div>
            <div className="field">
              <textarea
                onChange={this.handleChange}
                name="message"
                id="message"
                placeholder="Message"
                rows="7" 
              ></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <input
                disabled={!this.state.message || !this.state.email || !this.state.name || this.state.sending}
                type="submit"
                value="Send Message"
                className="button large primary"
              />
            </li>
          </ul>
        </form>
    );
  }
}